// NOTE:
// If you edit this file, please make sure to extensively QA our application!

// All colors should map to our Figma, which is the source of truth.
// https://www.figma.com/file/bO6k7BWONhVqWMeI1Woqa1/%F0%9F%8C%BF-Tokens

const blue = {
  /**
   * @description
   * Accepted shades of `blue`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  '25': '#f4f9fe',
  '50': '#e8f3ff',
  '100': '#cde4fd',
  '200': '#a9d2fe',
  '300': '#76b6fa',
  '400': '#3b8ee8',
  '500': '#196dca',
  '600': '#0e5297',
  '700': '#073869',
  '800': '#032242',
  '900': '#05192f',
} as const;

const indigo = {
  /**
   * @description
   * Accepted shades of `indigo`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  '25': '#f8f7ff',
  '50': '#f1f0ff',
  '100': '#dddefe',
  '200': '#c7c8fe',
  '300': '#a9a7f7',
  '400': '#8576f3',
  '500': '#6d44ef',
  '600': '#5322c5',
  '700': '#38158b',
  '800': '#220a5a',
  '900': '#150d36',
} as const;

const red = {
  /**
   * @description
   * Accepted shades of `red`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  '25': '#fff6f6',
  '50': '#ffeded',
  '100': '#fed6d6',
  '200': '#febab6',
  '300': '#f78f89',
  '400': '#e5554e',
  '500': '#bd383f',
  '600': '#8f282d',
  '700': '#64191d',
  '800': '#3f0c13',
  '900': '#270c0e',
} as const;

const yellow = {
  /**
   * @description
   * Accepted shades of `yellow`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   * @todo Rename to `gold` to match Figma library.
   */
  '25': '#fdf9ea',
  '50': '#fbf3c9',
  '100': '#fbe08a',
  '200': '#fcc44c',
  '300': '#f0a732',
  '400': '#d17a00',
  '500': '#b65a14',
  '600': '#8b440f',
  '700': '#5a2800',
  '800': '#391601',
  '900': '#241005',
} as const;

const green = {
  /**
   * @description
   * Accepted shades of `green`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  '25': '#f0fefa',
  '50': '#e6faf3',
  '100': '#b4f0e0',
  '200': '#86e6d0',
  '300': '#4fc8b4',
  '400': '#13a291',
  '500': '#088075',
  '600': '#165f59',
  '700': '#054140',
  '800': '#002c2c',
  '900': '#061a1b',
} as const;

const gray = {
  /**
   * @description
   * Accepted shades of `gray`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  '25': '#f7f8fc',
  '50': '#eff2f8',
  '100': '#dbe2ee',
  '200': '#c4cee1',
  '300': '#a4b1cd',
  '400': '#7d8cb0',
  '500': '#5e6d96',
  '600': '#495073',
  '700': '#303653',
  '800': '#1b1f3a',
  '900': '#141832',
} as const;

const darkOverlay = {
  '300': 'rgba(40, 54, 83, 0.3)',
  '600': 'rgba(40, 54, 83, 0.6)',
  '800': 'rgba(40, 54, 83, 0.8)',
} as const;

const lightOverlay = {
  '300': 'rgba(255, 255, 255, 0.3)',
  '600': 'rgba(255, 255, 255, 0.6)',
  '800': 'rgba(255, 255, 255, 0.8)',
} as const;

const white = {
  white: '#FFFFFF',

  /**
   * @deprecated
   * Consider `lightOverlay.600`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  white52: 'rgba(255, 255, 255, 0.52)',

  /**
   * @deprecated
   * Consider `lightOverlay.300`.
   * See [Trellis' color documentation](https://lattice.latticehq.com/playground/foundations/colors) for the full list of accepted color values.
   */
  white32: 'rgba(255, 255, 255, 0.32)',
} as const;

/**
 * @todo Move background, border, and glyph values to `tokens` object.
 */
export const semantic = {
  attention: {
    ...yellow,
    background: yellow[50],
    border: yellow[300],
    glyph: yellow[500],
  },
  warning: {
    ...red,
    background: red[50],
    border: red[300],
    glyph: red[500],
  },
  info: {
    ...blue,
    background: blue[50],
    border: blue[300],
    glyph: blue[500],
  },
  neutral: {
    ...gray,
    background: gray[50],
    border: gray[300],
    glyph: gray[600],
  },
  success: {
    ...green,
    background: green[50],
    border: green[300],
    glyph: green[500],
  },
  accent: {
    ...indigo,
    background: indigo[50],
    border: indigo[300],
    glyph: indigo[500],
  },
} as const;

/**
 * @description
 * These color tokens are designed to provide consistent values for common UI elements and should
 * align with the color tokens in our [Figma library](https://www.figma.com/file/GkgpXmPprgAHoZf8AN2wVL/Foundations?node-id=2197%3A20702).
 */
const tokens = {
  background: {
    accent: semantic.accent[50],
    attention: semantic.attention[50],
    dark: semantic.neutral[900],
    disabled: semantic.neutral[50],
    info: semantic.info[50],
    offset: semantic.neutral[25],
    neutral: semantic.neutral[50],
    success: semantic.success[50],
    warning: semantic.warning[50],
    overlay: darkOverlay[600],
  },
  borders: {
    /**  @todo rename default color borders to neutral & rename default names across all colors to include 'Default' **/
    darkest: semantic.neutral[500],
    dark: semantic.neutral[400],
    default: semantic.neutral[300],
    light: semantic.neutral[200],
    lightest: semantic.neutral[100],

    disabled: semantic.neutral[100],
    hover: semantic.neutral[400],

    infoDark: semantic.info[500],
    info: semantic.info[400],
    infoLight: semantic.info[300],
    infoLightest: semantic.info[200],

    successDark: semantic.success[500],
    success: semantic.success[400],
    successLight: semantic.success[300],
    successLightest: semantic.success[200],

    attentionDark: semantic.attention[500],
    attention: semantic.attention[400],
    attentionLight: semantic.attention[300],
    attentionLightest: semantic.attention[200],

    warningDark: semantic.warning[500],
    warning: semantic.warning[400],
    warningLight: semantic.warning[300],
    warningLightest: semantic.warning[200],

    accentDark: semantic.accent[500],
    accent: semantic.accent[400],
    accentLight: semantic.accent[300],
    accentLightest: semantic.accent[200],

    focus: semantic.info[500], // Should always be accompanied by a focus ring
    focusRing: semantic.info[200],

    /**
     * @deprecated
     **/
    invalid: semantic.warning[400],
  },
  icon: {
    accent: semantic.accent[500],
    attention: semantic.attention[500],
    dark: semantic.neutral[700],
    default: semantic.neutral[600],
    light: semantic.neutral[500],
    disabled: semantic.neutral[400],
    link: semantic.info[500],
    success: semantic.success[500],
    warning: semantic.warning[500],
    white: white.white,
  },
  text: {
    default: semantic.neutral[800],
    primary: semantic.neutral[800],
    secondary: semantic.neutral[600],
    disabled: semantic.neutral[500],
    placeholder: semantic.neutral[500],

    neutral: semantic.neutral[700],
    neutralHover: semantic.neutral[800],

    link: semantic.info[500],
    linkHover: semantic.info[600],

    info: semantic.info[600],

    success: semantic.success[600],
    successHover: semantic.success[700],

    attention: semantic.attention[600],
    attentionHover: semantic.attention[700],

    warning: semantic.warning[600],
    warningHover: semantic.warning[600],

    accent: semantic.accent[600],
    accentHover: semantic.accent[600],

    white: white.white,

    /**
     * @deprecated
     */
    emphasized: semantic.neutral[900],
    danger: semantic.warning[600],
  },

  /**
   * @deprecated
   * Use equivalent values from the `tokens` object.
   * These are used as text colors and should be shades of 500 or higher to meet contrast ratios.
   *
   * @todo Replace `border` with `borders.light`
   * @todo Map remaining values to new tokens
   */
  legacy: {
    focus: semantic.info[200],
    attention: semantic.attention[500],
    danger: semantic.warning[500],
    info: semantic.info[600],
    primary: semantic.neutral[800],
    secondary: semantic.neutral[600],
    success: semantic.success[500],
    good: semantic.success[500],
    warn: semantic.attention[500],
    bad: semantic.warning[500],
    notice: semantic.info[500],
    border: semantic.neutral[200],
  },
};

export const baseColors = {
  // Literal colors
  blue,
  gray,
  green,
  indigo,
  red,
  yellow,
  ...white,

  // Overlays
  darkOverlay,
  lightOverlay,

  /**
   * Included here to make sure types in [this file](apps/visage/src/components/common/theme.ts) don't need to be rewritten.
   * @todo Clean up that types file and deprecate legacy tokens.
   * */
  ...tokens.legacy,
} as const;

const getEntries = (o: any, prefix = ''): String[] =>
  Object.entries(o).flatMap(([k, v]) =>
    Object(v) === v ? getEntries(v, `${prefix}${k}.`) : [`${prefix}${k}`],
  );

export const baseColorsTokenList = getEntries(baseColors);

const dataViz = {
  red: {
    guava: '#E16363',
  },
  yellow: {
    mango: '#F6C07A',
  },
  green: {
    teal: '#7DCABB',
    olive: '#6D847F',
  },
  blue: {
    blueberry: '#6883AB',
  },
  purple: {
    eggplant: '#70608A',
    lilac: '#A57EE3',
  },
};

// For a full list, see https://brand-access.webflow.io/color.
const brand = {
  accent: {
    aquaObscura: '#046663',
  },
};

const colors = {
  ...baseColors,
  ...semantic,
  ...tokens,
  dataViz,
  brand,
} as const;

export default colors;
